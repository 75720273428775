export enum PlatformModel {
  DOZI = "dozi",
  ZI = "zi",
  DO = "do",
  ZI_LITE = 'zi lite',
  RECRUITER = "recruiter",
  MARKETING_CLOUD = "marketing_cloud",
  ZI_CHAT = "zi chat",
  ENGAGE = "engage",
  ADMIN = "admin",
  COMMUNITY = "community",
  TAM = "tam",
  OPERATIONS = "operationsos",
}
