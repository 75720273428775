import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {SsoLoginPopupComponent} from "./login/screens/sso-login-popup/sso-login-popup.component";
import {SsoLoginPopupCheckCookieCloseWindowComponent} from "./login/screens/sso-login-popup-check-cookie-close-window/sso-login-popup-check-cookie-close-window.component";
import { LoginAppsGuardService } from './login/guards/login-apps-guard.service';

const routes: Routes = [
  {
    path: 'sso-login-popup',
    component: SsoLoginPopupComponent
  },
  {
    path: 'sso-login-popup-check-cookie-close-window',
    component: SsoLoginPopupCheckCookieCloseWindowComponent,
    canActivate: [LoginAppsGuardService]
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  exports: [
    RouterModule
  ],
  /**
   * This app is not using the hash in the URL
   * **/
  imports: [
    RouterModule.forRoot(routes, {useHash: false, preloadingStrategy: PreloadAllModules})
  ]
})
export class AppRoutingModule {
}
