
export const ValidationConfig = {
  errorMessages: {
    text: {
      message: 'must fill out this field'
    },
    username: {
      message: 'Your username is not valid'
    },
    email: {
      message: 'Please provide a valid business email'
    },
    businessEmail: {
      message: 'Please provide a valid business email'
    },
    businessEmailEmpty: {
      message: 'Business email is required'
    },
    password: {
      message: 'Password must contain between 8 to 20 characters and at least one letter and one number'
    },
    password_required: {
      message: 'Your password is not valid'
    },
    domain: {
      message: 'Please enter a valid domain'
    },
    url: {
      message: 'Please enter a valid url'
    },
    textarea: {
      message: 'must fill out this field'
    },
    phoneNumber: {
      message: 'Please provide a valid phone number'
    },
    phoneNumberEmpty: {
      message: 'Phone number is required'
    },
    fullName: {
      message: 'Please provide a valid name'
    },
    fullNameEmpty: {
      message: 'Full name is required'
    },
    title: {
      message: 'Please provide a valid job title'
    },
    titleEmpty: {
      message: 'Job title is required'
    },
  }
};
