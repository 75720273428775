
export class User {
  _id?: string;
  name?: string;
  email?: string;
  phone?: number;
  phoneVerified?: boolean;
  isEmployeeUser?: boolean;
  twoFaMethod?: string;
  twoFaMethodLocked?: boolean;
  redirectUrl?: string;
  isAdmin?: boolean;
  credits?: number;
  zoomCompanyId?: number;
  zoomRoles?: string[];
  toggle?: boolean;
  toggleFrom?: string;
  toggleTo?: string;
  referrer?: string;
}

export const UserInitialState: User = {
  _id: null,
  name: '',
  email: '',
  phone: null,
  phoneVerified: false,
  isEmployeeUser: false,
  twoFaMethod: '',
  twoFaMethodLocked: false,
  redirectUrl: '',
  zoomCompanyId: 0,
  zoomRoles: [],
  isAdmin: false,
  credits: 0,
  toggle: false,
  toggleFrom: '',
  toggleTo: '',
  referrer: ''
};
