import {Injectable, Inject} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {Meta, MetaDefinition, Title} from '@angular/platform-browser';

@Injectable()
export class TagsInjectionService {

  constructor(@Inject(DOCUMENT) private document,
              private meta: Meta,
              private titleService: Title) {}

  addLinkTag(attrArray: Array<{name: string, value: string}>) {
    const linkTag: HTMLLinkElement = this.document.createElement('link');
    for (const attr of attrArray) {
      linkTag.setAttribute(attr.name, attr.value);
    }
    return this.document.head.appendChild(linkTag);
  }

  setCanonicalTag(canonicalHref: string) {
    const currentCanonicalTag: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
    if (currentCanonicalTag) {
      return currentCanonicalTag.setAttribute('href', canonicalHref);
    }
    return this.addLinkTag([
      {name: 'rel', value: 'canonical'},
      {name: 'href', value: canonicalHref}
    ]);
  }

  addMetaTags(metaArray: Array<MetaDefinition>) {
    return this.meta.addTags(metaArray);
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }

  addScriptTag(src: string) {
    const scriptTag = this.document.createElement('script');
    scriptTag.setAttribute('src', src);
    this.document.head.appendChild(scriptTag);
  }
}
