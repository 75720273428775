
import {Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';

import {ActivatedRoute, Router} from '@angular/router';

import * as _ from 'lodash';

@Component({
  selector: 'zi-sso-login-popup',
  templateUrl: './sso-login-popup.component.html',
  styleUrls: ['./sso-login-popup.component.scss']
})
export class SsoLoginPopupComponent implements OnInit, OnDestroy {

  constructor(private _router: Router, private activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {

    this.activatedRoute.queryParams
      .subscribe(params => {
        const redirectUrl = params.redirectUrl;
        const ssoLoginPopup = document.getElementById("sso-login-popup") as HTMLAnchorElement;
        console.log("ssoLoginPopup.href="+ssoLoginPopup.href);

        // this IDP login url
        ssoLoginPopup.href = redirectUrl;
        ssoLoginPopup.click();

      }

    );


  }

  ngOnDestroy() {
  }

}
