
import {Component, Inject, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginCookieService} from "../../../core/services/login-cookie.service";
import {environment} from "../../../../environments/environment";
import * as prodEnv from '../../../../environments/environment.prod';
import * as preprodEnv from '../../../../environments/environment.pre-prd';

import * as _ from 'lodash';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'zi-sso-login-popup-check-cookie-close-window',
  templateUrl: './sso-login-popup-check-cookie-close-window.component.html',
  styleUrls: ['./sso-login-popup-check-cookie-close-window.component.scss']
})
export class SsoLoginPopupCheckCookieCloseWindowComponent implements OnInit, OnDestroy {

  constructor(private activatedRoute: ActivatedRoute, private loginCookieService: LoginCookieService) {
  }

  ngOnInit() {

    this.activatedRoute.queryParams
      .subscribe(params => {
        const redirectUrl = params.redirectUrl;
        const openerOrigin = this.loginCookieService.getCookie('openerOrigin');
        console.log("redirectUrl="+redirectUrl);
        console.log('openerOrigin', openerOrigin);
        if (openerOrigin) {
          this.handlePreprodOpener(openerOrigin, location.href, redirectUrl);
          this.loginCookieService.deleteCookie('openerOrigin', '/', environment.cookies_domain);
        }
        localStorage.setItem('popupredirecturl', redirectUrl);
        if (window.opener) {
          console.log('window.opener origin', window.opener.origin);
          window.opener.postMessage({popupredirecturl: redirectUrl}, location.origin);
        }
        window.close();
      }
    );

  }

  ngOnDestroy() {
  }

  // if opener origin is preprod but popup origin is prod, redirect to preprod version of current page
  handlePreprodOpener(openerOrigin: string, popupUrl: string, redirectUrl: string) {
    const roSsoPopupRedirectUrl = localStorage.getItem('roSsoPopupRedirectUrl');
    if (LoginService.isProdEnv() && openerOrigin === preprodEnv.environment.app_domain) {
      let preprodUrl = popupUrl.replace(prodEnv.environment.app_domain, preprodEnv.environment.app_domain);  // replace first instance of app domain
      const redirectUrlPos = preprodUrl.indexOf('redirectUrl=');
      const popupRedirectUrl = preprodUrl.substring(redirectUrlPos);
      preprodUrl = preprodUrl.replace(popupRedirectUrl, `redirectUrl=${roSsoPopupRedirectUrl ? roSsoPopupRedirectUrl : redirectUrl}`);
      console.log(`redirecting to ${preprodUrl}...`);
      location.href = preprodUrl;
    }
  }
}
