import { Injectable } from '@angular/core';
import {ValidationConfig} from './validation.config';

import * as _ from 'lodash';

@Injectable()
export class ValidationService {

  constructor() {
    //This is intentional
  }

  /**
   * Getting the relevant error message by the field ID
   * **/
  getErrorMessage(fieldId) {
    return _.get(ValidationConfig, `errorMessages[${fieldId}].message`, '');
  }
}
