import {Action} from '@ngrx/store';
import {User} from "../core/model/user";
import {Auth} from "../core/model/auth";
import {GeneralInfo} from "../core/model/GeneralInfo";


export const UPDATE_USER_STATE = 'UPDATE_USER_STATE';
export const UPDATE_AUTH_STATE = 'UPDATE_AUTH_STATE';
export const UPDATE_GENERAL_INFO_STATE = 'UPDATE_GENERAL_INFO_STATE';


export class UpdateUserState implements Action {
  public readonly type: string = UPDATE_USER_STATE;
  constructor(public payload: User) {}
}

export class UpdateAuthState implements Action {
  public readonly type: string = UPDATE_AUTH_STATE;
  constructor(public payload: Auth) {}
}

export class UpdateGeneralInfoState implements Action {
  public readonly type: string = UPDATE_GENERAL_INFO_STATE;
  constructor(public payload: GeneralInfo) {}
}


export type AppActions
  =
  UpdateUserState |
  UpdateAuthState |
  UpdateGeneralInfoState;
