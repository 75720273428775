/**
 * Production environment config
 */

import * as environemnt_prod from './environment.prod';

export const environment = {
  ...environemnt_prod.environment,
  is_pre_prod: true,
  name: "pre-prod",
  base_login_canonical: 'https://login-preprod.zoominfo.com',
  app_domain: 'https://login-preprod.zoominfo.com',
  login_backend_domain: 'https://login-preprod.zoominfo.com/api',

  ums_password_reset_url: "https://login.zoominfo.com/gw/ziapi/ums/v2/passwordReset",  // NOSONAR
  sso_redirect_url: 'https://gw-app.zoominfo.com/okta/ssoRedirect',
  //
  recruiter_url_enterprise: 'https://recruiter-app-pre.zoominfo.com',
  recruiter_url_community: 'https://recruiter-app-pre.zoominfo.com',
  recruiter_url_reachout: 'https://recruiter-app-pre.zoominfo.com/reachout/routing',
  //
  marketing_cloud_url_enterprise: 'https://marketingos-preprod.zoominfo.com',
  //
  engage_url_enterprise: 'https://engage-app-preprod.zoominfo.com',
  //
  admin_url_enterprise: 'https://admin-pre-prd.zoominfo.com/',
  admin_regression_url: "https://admin-regression.zoominfo.com/",
  //
  operations_url_enterprise: "https://operationsos.zoominfo.com/",
  zi_backoffice_url: 'https://backoffice.zoominfo.com/backoffice/home/index',
  //
  dozi_url_enterprise: 'https://app-preprod.zoominfo.com',
  dozi_url_community: 'https://app-preprod.zoominfo.com',
  dozi_url_reachout: 'https://app-preprod.zoominfo.com/#/reachout/routing',
  dozi_regression_url: "https://dozi-regression.zoominfo.com",
  dozi_lite_url: "https://apps-pre-prd.zoominfo.com/zi-lite/#/",
  dozi_lite_new_url: "https://zi-lite-pre-prd.zoominfo.com/#/",
  dozi_lite_regression_url: "https://apps-regression.zoominfo.com/zi-lite/",
  dozi_lite_new_regression_url: "https://zi-lite-regression.zoominfo.com/",
  //okta login configs
  googleIdpId: '0oab4d8z5l0FcXt9H696',
  microsoftIdpId: '0oab4drgiEFNcQtF7696',
  sfIdpId: "0oa6r202vpHAvosus697",
  oktaClientId: '0oa99dsmbnAxlevF3696',
  oktaLoginUrl: 'https://okta-login.zoominfo.com',
  oktaIssuer: 'https://okta-login.zoominfo.com/oauth2/default',
  oktaLegacyIssuer: 'https://zoominfo-auth.okta.com/oauth2/default',
  sm_ping_url: "https://login.zoominfo.com/gw/ziapi/okta/ping",
  sm_refresh_storage_url: "https://login.zoominfo.com/gw/ziapi/refreshToken",
  sm_refresh_access_token_url: "https://login.zoominfo.com/gw/ziapi/refreshAccessToken",
  oktaDeleteSessionUrl: "https://okta-login.zoominfo.com/api/v1/users/me/lifecycle/delete_sessions",
  oktaGetSessionsMeUrl: "https://okta-login.zoominfo.com/api/v1/sessions/me",

  skilljarUrlPartial: 'zoominfo-auth_skilljarproduction_1',
  // ZI-Lite sign up URL
  signup_url: 'https://www.zoominfo.com/signup',
  settings_url: 'https://app.zoominfo.com/ziapi/settings/api',
  play_store_url: 'https://play.google.com/store/apps/details?id=com.zoominfo.enterprise&hl=en',
};
