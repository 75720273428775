import * as AppActions from './app.actions';
import {User, UserInitialState} from '../core/model/user';
import {Auth, AuthInitialState} from '../core/model/auth';
import {GeneralInfo, GeneralInfoInitialState} from '../core/model/GeneralInfo';

import * as _ from 'lodash';

// App state interface
export interface AppState {
  user: User;
  auth: Auth;
  generalInfo: GeneralInfo;
}


// Initial State
const initialState: AppState = {
  user: UserInitialState,
  auth: AuthInitialState,
  generalInfo: GeneralInfoInitialState
};

// App Reducers
export function appReducers(state: any = initialState, action: AppActions.AppActions): AppState {
  switch (action.type) {

    // Reducer for Edit the User state
    case AppActions.UPDATE_USER_STATE: {
      state.user = {...state.user, ...action.payload};
      return state;
    }

    // Reducer for Update the Auth state
    case AppActions.UPDATE_AUTH_STATE: {
      state.auth = {...state.auth, ...action.payload};
      return state;
    }

    case AppActions.UPDATE_GENERAL_INFO_STATE: {
      state.generalInfo = {...state.generalInfo, ...action.payload};
      return state;
    }

    // On Default return the state
    default: {
      return state;
    }
  }
}


/**********************************************************
 * Selectors
 *********************************************************/

/**
 * Returns the app state.
 * @function getAppState
 * @param {State} state Top level state.
 * @return {State}
 */
export const getAppState = (state) => state.appState;

/**
 * Returns the user state.
 * @function getUserState
 * @param {State} state Top level state.
 * @return {State}
 */
export const getUserState = (state) => state.appState.user;

/**
 * Returns the auth state.
 * @function getAuthState
 * @param {State} state Top level state.
 * @return {State}
 */
export const getAuthState = (state) => state.appState.auth;

/**
 * Returns the generalInfo state.
 * @function getGeneralInfoState
 * @param {State} state Top level state.
 * @return {State}
 */
export const getGeneralInfoState = (state) => state.appState.generalInfo;
