
export class GeneralInfo {
  loginCaptchaNeeded?: boolean;
  forgotPasswordCaptchaNeeded?: boolean;
  oAuthLoginEnabled?: boolean;
  isUniversityPage?: boolean;
  isContributor?: boolean;
  contributorParam?: string;
  parsedContributorParam?: Object;
  isLoading?: boolean;
  forgotPwPageErrMessage?: string;
  pixelCaptchaRefId?: string;
  pixelCaptchaNeeded?: boolean;
  pixelInitScript?: string;
  customErrMessage?: string;
  platform?: string;
  availablePlatforms?: string[];
  ssoLoginErrMessage?: string;
}

export const GeneralInfoInitialState: GeneralInfo = {
  loginCaptchaNeeded: false,
  forgotPasswordCaptchaNeeded: false,
  oAuthLoginEnabled: false,
  isUniversityPage: false,
  isContributor: false,
  contributorParam: '',
  parsedContributorParam: {},
  isLoading: false,
  forgotPwPageErrMessage: '',
  pixelCaptchaRefId: '',
  pixelCaptchaNeeded: false,
  pixelInitScript: '',
  customErrMessage: '',
  platform: '',
  availablePlatforms: [],
  ssoLoginErrMessage: ''
};
