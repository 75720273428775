
<div class="zi-noty" *ngIf="message">

  <div class="zi-noty-message {{ getMessageProperties(message.type).styleClass }}">

    <div class="zi-noty-message-container">
      <div class="zi-noty-message-container-main">
        <img class="zi-noty-message-container-main-icon" *ngIf="getMessageProperties(message.type).imageSrc" [src]="getMessageProperties(message.type).imageSrc" alt="error">
        <p class="zi-noty-message-container-main-content" [innerHTML]="message.content"></p>
      </div>
      <span class="zi-noty-message-container-close" (click)="deleteMessage()">&times;</span>
    </div>

  </div>

</div>
