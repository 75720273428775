
export class Auth {
  parseSessionToken?: string;
  token?: string;
  inlineQrCodeImage?: string;
  twoFactorAuthPinId?: string;
  universityLoginToken?: string;
  resetPwErrCode?: number;
  resetPwToken?: string;
}

export const AuthInitialState: Auth = {
  parseSessionToken: '',
  token: '',
  inlineQrCodeImage: '',
  twoFactorAuthPinId: '',
  universityLoginToken: '',
  resetPwErrCode: 0,
  resetPwToken: ''
};
