import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotyComponent} from './components/noty/noty.component';
import { CaptchaComponent } from './components/captcha/captcha.component';
import {RecaptchaModule} from 'ng-recaptcha';
import {DialogTemplateComponent} from "./components/dialog-template/dialog-template.component";
import {DialogFooterComponent} from "./components/dialog-template/dialog-footer/dialog-footer.component";
import {DialogContentComponent} from "./components/dialog-template/dialog-content/dialog-content.component";
import { PlatformSelectDialogComponent } from './components/platform-select-dialog/platform-select-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import {CommonService} from "./common.service";
import { MaintenanceBannerComponent } from './components/maintenance-banner/maintenance-banner.component';
import { AnalyticsService } from '../core/services/analytics.service';


@NgModule({
  imports: [
    CommonModule,
    RecaptchaModule,
    MatDialogModule
  ],
  declarations: [
    MaintenanceBannerComponent,
    NotyComponent,
    CaptchaComponent,
    DialogTemplateComponent,
    DialogContentComponent,
    DialogFooterComponent,
    PlatformSelectDialogComponent
  ],
  providers: [
    CommonService,
    AnalyticsService
  ],
  exports: [
    NotyComponent,
    CaptchaComponent,
    DialogTemplateComponent,
    MaintenanceBannerComponent
  ]
})
export class ZiCommonModule {
}
