import { BrowserModule } from '@angular/platform-browser';
import {EnvironmentProviders, NgModule, Provider, Type} from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {TagsInjectionService} from "./core/services/tags-injection.service";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {StoreModule} from '@ngrx/store';
import {appReducers} from './ngrx/app.reducres';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {ValidationService} from './core/services/validation/validation.service';
import {ZiCommonModule} from './common/common.module';
import {NotyService} from './common/components/noty/noty.service';
import {UrlService} from "./core/services/url.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {CookieService} from 'ngx-cookie-service';
import {LoginCookieService} from "./core/services/login-cookie.service";
import {SsoLoginPopupComponent} from "./login/screens/sso-login-popup/sso-login-popup.component";
import {SsoLoginPopupCheckCookieCloseWindowComponent} from "./login/screens/sso-login-popup-check-cookie-close-window/sso-login-popup-check-cookie-close-window.component";
import { LoginAppsGuardService } from './login/guards/login-apps-guard.service';

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: (Provider | EnvironmentProviders)[];
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SsoLoginPopupComponent,
    SsoLoginPopupCheckCookieCloseWindowComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ZiCommonModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({appState: appReducers},
    {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 50, // Retains last 50 states
      logOnly: environment.production // Restrict extension to log-only mode
    }),
    BrowserAnimationsModule
  ],
  providers: [
    TagsInjectionService,
    ValidationService,
    NotyService,
    UrlService,
    CookieService,
    LoginCookieService,
    LoginAppsGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
