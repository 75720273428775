
import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {

  constructor() {
    // this is intentional
  }

  /**
   * This method is responsible for deciding if login page opened for DOZI app or LZI app
   * we check each of the LZI options and if one of them - its LZI version of login, and if non of them - its DOZI login
   *
   * options for being LZI version:
   * 1. lzi subdomain and not regular unified login subdomain
   * 2. is redirect url belongs to LZI app (catches both LZI platform and LZI reachout)
   * 3. is LZI SFNA - this is decided if login page is opened from within an iframe, and no query params
   * ** isReachout flag is passed to login page for DOZI reachout apps so we check if this flag is not presented as well for the LZI condition
   *
   * **/
  isLziLoginVersion() {
    return false;  // legacy login is no longer supported
  }

}
