
import {of as observableOf, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as _ from 'lodash';
import { LoginService } from '../service/login.service';

@Injectable()
export class LoginAppsGuardService implements CanActivate {

  constructor(private router: Router) {
  }

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

    const queryParams = activatedRouteSnapshot.queryParams;
    const { app } = queryParams;
    //
    const appMapping = {sfna: {redirectTo: 'sfna'}, cero: {redirectTo: 'cero'}};
    LoginService.handleRoSsoPopupRedirectUrl(window.location.href);
    if (app && appMapping[app]) {
      // Redirect without rendering view
      // Removing "app" from query params
      const newQp = _.omitBy({...queryParams, app: undefined}, _.isNil);
      this.router.navigate([`./${appMapping[app].redirectTo}`], {queryParams: newQp});
      return observableOf(false);
    }
    else {
      // Rendering view
      return observableOf(true);
    }
  }

}
